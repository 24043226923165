import differenceInYears from "date-fns/differenceInYears";
import differenceInDays from "date-fns/differenceInDays";
import {endOfYear, startOfYear, subYears} from "date-fns";

function calculateCpiRateByYears(totalYears, inflationPercentage) {
  let rate = (100 + Number.parseFloat(inflationPercentage)) / 100;

  return Math.pow(rate, totalYears)
}

export function cpiRatesHelper(startDate, endDate, timePeriods, inflationPercentage) {
  const rates = [];
  for (let i = 0; i < timePeriods.length; i++) {
    const diffInYears =  Math.abs(differenceInYears(startDate, timePeriods[i]))
    const diffPureDays = Math.abs(differenceInDays(startDate, subYears(timePeriods[i], diffInYears)))
    const totalDaysInYear = Math.abs(differenceInDays(startOfYear(timePeriods[i]), endOfYear(timePeriods[i]))) + 1

    const pureDaysRate = (diffPureDays / totalDaysInYear) * inflationPercentage / 100 + 1
    const yearsRate = calculateCpiRateByYears(diffInYears, inflationPercentage)

    rates[i] = yearsRate * pureDaysRate
  }

  return rates;
}

export default function cpiRates(fiat, rates, index) {
  return Math.pow(fiat, 2) / (rates[index] * fiat)
}
