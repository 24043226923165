import { PricePredictions, StackingFrequency } from './types';
import { getTimePeriodDates, getTimePeriods } from './time-periods';
import { differenceInYears } from 'date-fns';
import { Decimal } from 'decimal.js';
import { interpolateRates } from './interpolation';
import cpiRates, { cpiRatesHelper } from './cpi-rates';
import { toBTC, toSat } from '../convertBtcSats';
// import isSameDay from 'date-fns/isSameDay';
type SatsGoalProps = {
  useStackingAmount: boolean;
  stackingAmount: number;
  currentStack: number;
  avgCostPerBTCPrice?: number;
  goal: number;
  startDate: Date;
  endDate: Date;
  stackingFrequency: StackingFrequency;
  pricePredictions: PricePredictions;
  increaseWithInflation: boolean;
  inflationPercent: number;
  supplyLimit: number;
  calculateFuturePurchasingPower: boolean;
  isSatoshis?: boolean;
};

type TableRowData = {
  date: Date;
  rate: Decimal;
  stackedCrypto: Decimal;
  cumulativeTotalCrypto: Decimal;
  cumulativeTotalFiat: Decimal;
  cumulativeTotalFiatPurchasingPower: Decimal;
  fiatValueDelta: Decimal;
  fiatValueDeltaPercentage: Decimal;
  cumulativeInvestedFiat: Decimal;
};

type SingleStatData = {
  averageCryptoPrice: Decimal;
  totalInvested: Decimal;
  totalValue: Decimal;
  investmentReturn: Decimal;
  investmentReturnPercentage: Decimal;
  totalCrypto: Decimal;
  purchasingPower: Decimal;
};

type SatsGoalOutputs = {
  numTimePeriods: number;
  startDate: Date;
  amountToInvest: Decimal;
  tableData: Array<TableRowData>;
  singleStatData: SingleStatData;
  breachedLimit: boolean;
  stackOnLast: boolean;
};

function formatTable(labels: string[], columns: any[]): any[] {
  const nRows = columns[0].length;
  return Array.from(Array(nRows)).map((_, idx) => {
    let row = {};
    labels.forEach((label, columnIdx) => {
      row[label] = columns[columnIdx][idx];
    });
    return row;
  });
}

export function calculateSatsGoal({
  useStackingAmount,
  stackingAmount,
  currentStack: currentStackWithoutAvg,
  avgCostPerBTCPrice,
  goal,
  startDate,
  endDate,
  stackingFrequency,
  pricePredictions,
  increaseWithInflation,
  inflationPercent,
  supplyLimit,
  calculateFuturePurchasingPower,
  isSatoshis,
}: SatsGoalProps): SatsGoalOutputs {

  // work out how many stacking periods there are between startDate and endDate
  const numTimePeriods = getTimePeriods({
    startDate,
    endDate,
    stackingFrequency,
  });
  // get the dates of those stacking periods
  const { dates: timePeriods, stackOnLast } = getTimePeriodDates({
    numTimePeriods,
    startDate,
    endDate,
    stackingFrequency,
  });

  // interpolate the exchange rates at each time period
  //
  // fiatToCryptoRates -> £100 * rate = ? BTC/Sats
  // cryptoToFiatRates => 1BTC/Sats * rate = £?

  // Returns the estimated cost of btc at each time period
  const cryptoToFiatRates = interpolateRates({
    startDate,
    timePeriods,
    pricePredictions,
  })?.map((value) => new Decimal(value));
  
  // Returns the estimated cost of fiat at each time period
  const fiatToPurchasingFiatRates = interpolateRates({
    startDate,
    timePeriods,
    pricePredictions: [
      {
        date: startDate,
        value: 0,
      },
      {
        date: endDate,
        value: inflationPercent / 100,
      },
    ],
  })?.map((value) => new Decimal(value));

  const fiatToCryptoRates = cryptoToFiatRates?.map((value) =>
  new Decimal(1).dividedBy(value)
  );

  if (
    !cryptoToFiatRates ||
    !fiatToCryptoRates ||
    (useStackingAmount && increaseWithInflation && !inflationPercent) ||
    (useStackingAmount && !stackingAmount) ||
    // no price prediction is provided
    !pricePredictions ||
    !pricePredictions[1].value
  )
    return {} as any;

  const currentStack = +currentStackWithoutAvg;
  const currentStackCalculation = cryptoToFiatRates[0];
  
    const avgBTCAsDecimal = new Decimal(avgCostPerBTCPrice || 0);
    const currentStackAtAvgBTCCostRate = new Decimal(currentStack)?.mul(avgBTCAsDecimal)
    const currentStackAtTodaysRate = new Decimal(currentStack || 0)?.mul(
      currentStackCalculation
    )

  const fiatCurrentStack = !!avgCostPerBTCPrice ? currentStackAtAvgBTCCostRate : currentStackAtTodaysRate;

  let amountToInvest: Decimal, invested: Array<Decimal>;
  if (useStackingAmount) {
    amountToInvest = new Decimal(stackingAmount);
    if (increaseWithInflation) {
      invested = timePeriods.map((date) => {
        const yearsApart = new Decimal(differenceInYears(date, startDate));
        // 1 + precent increase
        const increaseRate = new Decimal(1).plus(
          new Decimal(inflationPercent).dividedBy(new Decimal(100))
        );
        return amountToInvest.times(increaseRate.pow(new Decimal(yearsApart)));
      });
    } else {
      invested = timePeriods.map(() => new Decimal(amountToInvest));
    }
  } else {
    // calculate the monthly fiat amount
    const sumRates = fiatToCryptoRates.reduce((acc, rate, idx) => {
      if (!stackOnLast && idx === fiatToCryptoRates.length - 1) return acc; // don't count the last if we're not stacking on it
      return acc.plus(rate);
    }, new Decimal(0));
    amountToInvest = new Decimal(goal)
      .minus(currentStack || 0)
      .dividedBy(sumRates);
    invested = timePeriods.map(() => new Decimal(amountToInvest));
  }

  if (!stackOnLast) {
    invested[invested.length - 1] = new Decimal(0);
  }
  if (currentStack) {
    invested[0] = fiatCurrentStack.plus(invested[0]);
  }

  let breachedLimit = false;

  // how much crypto did we buyin this time period
  const stackedCrypto = cryptoToFiatRates.map((rate: Decimal, idx) => {
    if (idx === 0 && currentStack && !!avgCostPerBTCPrice){
      const investedMinusCurrentStack = invested[0].minus(currentStackAtAvgBTCCostRate);
      return investedMinusCurrentStack.dividedBy(rate);
    }
    return invested[idx].dividedBy(rate)
  }
  );

  // what is our running crypto balance
  const cumulativeTotalCrypto = stackedCrypto.reduce(
    (arr, stacked: Decimal, idx) => {
      const lastTotal: Decimal =
        arr.length > 0 ? arr[arr.length - 1] : new Decimal(0);
      let newTotal = lastTotal.plus(stacked);

      if (newTotal.greaterThanOrEqualTo(new Decimal(supplyLimit))) {
        breachedLimit = true;
        newTotal = new Decimal(supplyLimit);
        const newStacked = newTotal.minus(lastTotal);
        invested[idx] = newStacked.times(cryptoToFiatRates[idx]);
        stackedCrypto[idx] = newStacked;
      }

      if (idx === 0 && !!currentStack) return [...arr, stacked.plus(currentStack)]

      return [...arr, newTotal];
    },
    []
  );
 
  // therefore, what is our investment worth at each time period in fiat currency
  const cumulativeTotalFiat = cumulativeTotalCrypto.map((crypto, idx) => {
    const rate = cryptoToFiatRates[idx];
    return crypto.times(rate);
  }, []);

  const cpiHelper = cpiRatesHelper(
    startDate,
    endDate,
    timePeriods,
    inflationPercent
  );

  const cumulativeTotalFiatPurchasingPower = cumulativeTotalFiat.map(
    (totalFiat, idx) => {
      if (!calculateFuturePurchasingPower) {
        return undefined;
      }
      const rate = fiatToPurchasingFiatRates[idx];
      // return new Decimal(cumulativeTotalFiat[idx]).minus(totalFiat.times(rate))
      return new Decimal(
        cpiRates(cumulativeTotalFiat[idx].toNumber(), cpiHelper, idx)
      );
    }
  );

  const cumulativeInvestedFiat = invested.reduce(
    (arr, investedSingle: Decimal) => {
      const last: Decimal = arr.length ? arr[arr.length - 1] : new Decimal(0);
      return [...arr, last.plus(investedSingle)];
    },
    []
  );  

  const fiatValueDelta = cumulativeTotalFiat.reduce(
    (arr, fiatValue: Decimal) => {
      const previous: Decimal =
        arr.length > 0 ? arr[arr.length - 1] : new Decimal(0);
      const delta = fiatValue.minus(previous);
      return [...arr, delta];
    },
    []
  );


  const fiatValueDeltaPercentage = cumulativeTotalFiat.reduce(
    (arr, fiatValue: Decimal, idx) => {
      const previous: Decimal =
        idx === 0 ? new Decimal(0) : cumulativeTotalFiat[idx - 1];
      const delta = fiatValue.minus(previous);
      const deltaPercentage = previous.greaterThan(0)
        ? delta.dividedBy(previous)
        : null;
      return [...arr, deltaPercentage];
    },
    []
  );

  const cumulativeFiatGain = cumulativeTotalFiat.reduce(
    (arr, total: Decimal, idx) => {
      const totalInvested: Decimal = cumulativeInvestedFiat[arr.length];
      // When sats, the gain is much less
      const gain = total.minus(totalInvested);
      return [...arr, gain];
    },
    []
  );

  const fiatGain = cumulativeFiatGain.reduce((arr, gain: Decimal) => {
    const lastGain: Decimal =
      arr.length > 0 ? cumulativeFiatGain[arr.length - 1] : new Decimal(0);
    return [...arr, gain.minus(lastGain)];
  }, []); 

  const tableData = formatTable(
    [
      'date',
      'cryptoToFiatRates',
      'fiatToCryptoRates',
      'stackedCrypto',
      'cumulativeTotalCrypto',
      'cumulativeTotalFiat',
      'fiatValueDelta',
      'fiatValueDeltaPercentage',
      'cumulativeInvestedFiat',
      'fiatGain',
      'cumulativeFiatGain',
      'cumulativeTotalFiatPurchasingPower',
    ],
    [
      timePeriods,
      cryptoToFiatRates,
      fiatToCryptoRates,
      stackedCrypto,
      cumulativeTotalCrypto,
      cumulativeTotalFiat,
      fiatValueDelta,
      fiatValueDeltaPercentage,
      cumulativeInvestedFiat,
      fiatGain,
      cumulativeFiatGain,
      cumulativeTotalFiatPurchasingPower,
    ]
  );

  // singlestats
  const totalValue = cumulativeTotalFiat[cumulativeTotalFiat.length - 1];
  const totalInvested = invested.reduce(
    (acc, amount) => acc.plus(amount),
    new Decimal(0)
  );
  const totalCrypto = cumulativeTotalCrypto[cumulativeTotalCrypto.length - 1];
  const averageCryptoPrice = totalInvested.dividedBy(totalCrypto);
  const investmentReturn: Decimal = totalValue?.minus(totalInvested);
  const investmentReturnPercentage = investmentReturn
    ?.times(new Decimal(100))
    ?.dividedBy(totalInvested);

  const singleStatData = {
    totalInvested,
    totalValue,
    averageCryptoPrice,
    investmentReturn,
    investmentReturnPercentage,
    totalCrypto,
    purchasingPower:
      cumulativeTotalFiatPurchasingPower &&
        cumulativeTotalFiatPurchasingPower.length
        ? cumulativeTotalFiatPurchasingPower[
        cumulativeTotalFiatPurchasingPower.length - 1
        ]
        : null,
  };

  // create outputs table at each time period
  return {
    numTimePeriods,
    startDate,
    amountToInvest,
    tableData,
    singleStatData,
    breachedLimit,
    stackOnLast,
  };
}
