import { PricePredictions } from "./types";
import differenceInDays from 'date-fns/differenceInDays';
import { linear } from "everpolate";

type InterpolateRatesProps = {
  startDate: Date;
  pricePredictions: PricePredictions;
  timePeriods: Array<Date>;
};

export function interpolateRates({
  pricePredictions,
  timePeriods,
  startDate,
}: InterpolateRatesProps): Array<any> {
  const basePredictions = pricePredictions.filter(({ date, value }) => !!date);
  if (basePredictions.length < 2) {
    // why 2 non-zero?
    // we need at least 2 non-zero points to draw a straight line and interpolate between.
    return null;
  }

  const priceXs = basePredictions.map(({ date }) => differenceInDays(date, startDate));
  const priceYs = basePredictions.map(({ value }) => Number(value));

  const desiredXs = timePeriods.map((date) => differenceInDays(date, startDate));

  const outputs = linear(desiredXs, priceXs, priceYs);

  return outputs;
}
