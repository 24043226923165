import addYears from 'date-fns/addYears';
import parseISO from 'date-fns/parseISO';
import { useAtomValue } from 'jotai';
import React from 'react';
import { useFormState } from 'react-final-form';
import { calculateSatsGoal } from 'utils/calculation';
import { dateOnly } from 'utils/date';
import { useRawCryptoValue } from 'utils/useCryptoValue';
import * as calculator from '../../../atoms/calculator';
import { toBTC } from '../../../utils/convertBtcSats';
import { useLogic } from '../../../utils/logicContext';
import { CalculationContext } from './calculationContext';

function formattedGrowth(growthValue) {
  if (typeof growthValue !== 'number') {
    return growthValue.split('_')[0];
  }

  return growthValue;
}

const getPricePoints = ({
  pricePredictions,
  showPrediction,
  growth,
  startDate,
  goalDate,
  priceNow,
  isSatoshis,
}) => {
  // user has input custom points
  if (showPrediction && pricePredictions) {
    return pricePredictions
      .filter(({ date, value }) => date && value)
      .map(({ date, value }) => ({
        date,
        value,
      }));
  }

  // growth rate specified
  const growthFactor = 1 + formattedGrowth(growth) / 100;

  const pricePoints = [{ date: startDate, value: priceNow }];

  while (pricePoints[pricePoints.length - 1].date < goalDate) {
    const lastPoint = pricePoints[pricePoints.length - 1];
    pricePoints.push({
      date: addYears(lastPoint.date, 1),
      value: lastPoint.value * growthFactor,
    });
  }

  return pricePoints;
};

const Calculation = ({ children }) => {
  const startingStack = useAtomValue(calculator.startingStackAtom);
  const avgStartStackBTCPrice = useAtomValue(calculator.avgCostPerBTCPriceAtom);
  const {
    valid,
    values: {
      useStackingAmount,
      stackingAmount,
      goal,
      goalDate: rawGoalDate,
      stackingFrequency,
      currency,
      isSatoshis,
      pricePredictions,
      showPrediction,
      growth,
      increaseWithInflation,
      inflationPercent,
      calculateFuturePurchasingPower,
    },
  } = useFormState();
  const { data } = useRawCryptoValue();
  const price = currency && data ? data[currency] : {};

  const priceNow = price;
  const defaultDate = addYears(new Date(), 1);
  const goalDate =
    rawGoalDate instanceof Date
      ? rawGoalDate
      : rawGoalDate
      ? parseISO(rawGoalDate)
      : defaultDate;
  const logic = useLogic();
  const supplyLimit = logic.BTCSupplyLimit;
  const startingStackBTC = isSatoshis ? +toBTC(startingStack) : startingStack;
  const goalInBTC = isSatoshis ? +toBTC(goal) : goal;

  const avgStackPriceToUse = avgStartStackBTCPrice === 0 ? priceNow : avgStartStackBTCPrice;

  // Checking inputs and show chart if it's valid
  const calculatedValues = React.useMemo(() => {
    if (!valid) return {};
    if (growth === null) return {};

    const startDate = dateOnly(new Date());
    // JTD: All inputs are now in BTC
    return calculateSatsGoal({
      useStackingAmount,
      stackingAmount,
      currentStack: startingStackBTC,
      avgCostPerBTCPrice: avgStackPriceToUse,
      startDate,
      endDate: goalDate,
      goal: goalInBTC,
      stackingFrequency,
      increaseWithInflation,
      inflationPercent,
      pricePredictions: getPricePoints({
        pricePredictions,
        showPrediction,
        growth,
        startDate,
        goalDate,
        priceNow,
        isSatoshis,
      }),
      supplyLimit,
      calculateFuturePurchasingPower,
      isSatoshis, // might be needed for future calculations
    });
  }, [
    currency,
    valid,
    useStackingAmount,
    startingStack,
    avgStartStackBTCPrice,
    stackingAmount,
    goal,
    goalDate,
    stackingFrequency,
    pricePredictions,
    showPrediction,
    growth,
    isSatoshis,
    priceNow,
    increaseWithInflation,
    inflationPercent,
    supplyLimit,
    calculateFuturePurchasingPower,
  ]);

  // JTD: Investigate what is impacted - can this all be in BTC?
  const value = {
    useStackingAmount,
    stackingAmount,
    currentStack: startingStack,
    goal,
    goalDate,
    stackingFrequency,
    currency,
    isSatoshis,
    ...calculatedValues,
  };

  return (
    <CalculationContext.Provider value={value}>
      {children}
    </CalculationContext.Provider>
  );
};
export default Calculation;
