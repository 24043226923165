import differenceInDays from 'date-fns/differenceInDays';
import differenceInWeeks from 'date-fns/differenceInWeeks';
import differenceInMonths from 'date-fns/differenceInMonths';
import differenceInYears from 'date-fns/differenceInYears';
import addDays from 'date-fns/addDays';
import addWeeks from 'date-fns/addWeeks';
import addMonths from 'date-fns/addMonths';
import addYears from 'date-fns/addYears';
import { StackingFrequency } from './types';
import isSameDay from 'date-fns/isSameDay';

type TimePeriodProps = {
    startDate: Date;
    endDate: Date;
    stackingFrequency: StackingFrequency;
};

export function getTimePeriods({ startDate, endDate, stackingFrequency }: TimePeriodProps): number {
    switch (stackingFrequency) {
        case "DAILY":
            return differenceInDays(endDate, startDate) + 1;
        case "WEEKLY":
            return differenceInWeeks(endDate, startDate) + 1;
        case "MONTHLY":
            return differenceInMonths(endDate, startDate) + 1;
        case "YEARLY":
            return differenceInYears(endDate, startDate) + 1;
    }
};

type TimePeriodDatesProps = {
    numTimePeriods: number;
    startDate: Date;
    endDate: Date;
    stackingFrequency: StackingFrequency;
};
export type TimePeriodsResult = {
    dates: Date[];
    stackOnLast: boolean;
}

export function getTimePeriodDates({ numTimePeriods, startDate, endDate, stackingFrequency }: TimePeriodDatesProps): TimePeriodsResult {
    const dates = Array.from(Array(numTimePeriods).keys()).map((_, idx) => {
        if (idx === 0) return startDate;
        switch (stackingFrequency) {
            case "DAILY":
                return addDays(startDate, idx);
            case "WEEKLY":
                return addWeeks(startDate, idx);
            case "MONTHLY":
                return addMonths(startDate, idx);
            case "YEARLY":
                return addYears(startDate, idx);
        }
    });
    let stackOnLast = true
    if (!isSameDay(dates[dates.length - 1], endDate)) {
        dates.push(endDate)
        stackOnLast = false
    }
    return { dates, stackOnLast };
}
